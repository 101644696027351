import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import { Helmet } from "react-helmet"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

import skilling from "../../images/icons/Icon/Skilling.png"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Popup from "../../components/Popup"
import { SectionDivider, HalfColumn, TwoColumn } from "../../components/global"
import { render } from "react-dom"
import parse from "html-react-parser"
import { useLocation } from "@reach/router"

const query = graphql`
  query {
    allStrapiFinancialInstitution(sort: { fields: Name, order: ASC }) {
      edges {
        node {
          id
          Name
          Brief_description
          Logo {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

const GetPartnerID = () => {
  var querystring = useLocation()
  var blog_id = querystring.search.substring(4)
  return blog_id
}

const NBFCBankPartner = () => (
  <Layout>
    <Helmet>
      <title>Dili – Zero Cost EMI Option for Professional Skilling</title>
      <meta
        name="description"
        content="Pay through a Dili no-cost EMI and signup for career/skill growth courses like AI & Machine learning, Digital Marketing, Part-time MBA and more."
      />
      <script src="/Jslibrary.js" defer></script>
    </Helmet>
    <Navigation></Navigation>
    <CategoryContainer>
      <StaticQuery
        query={query}
        render={(data) => (
          <StoreC>
            {data.allStrapiFinancialInstitution.edges
              .filter((partner) => partner.node.id === GetPartnerID())
              .map((partner) => (
                <Store>
                  <ImgContainer>
                    <StoreImg fixed={partner.node.Logo.childImageSharp.fixed} />
                  </ImgContainer>
                  <StoreText id={"StoreHeader_" + partner.node.id}>
                    {parse(partner.node.Brief_description)}
                  </StoreText>
                </Store>
              ))}
          </StoreC>
        )}
      />
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default NBFCBankPartner

export const CategoryContainer = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px;
  border: 0px solid blue;
`

export const CategoryHeader = styled.div`
  color: #142630;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-right: 40px;
`

export const CategoryText = styled.div`
  color: #465f6d;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-top: 20px;
  width: 100%;
  text-align: justify;
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
`

export const Store = styled.div`
  left: 50%;
  width: 100%;
  margin: 0px;
  margin-bottom: 24px;
  border: 0px solid brown;
`

export const ImgContainer = styled.div`
  height: 100px;
  border: 0px solid #dcdcdc;
  border-radius: 5px;
  position: relative;
`

export const StoreImg = styled(Img)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const StoreHeader = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 8px;
  font-weight: bold;
  width: 240px;
  // border:1px solid red;
`
export const StoreTextContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 0px solid #e6e6e6;
  margin-bottom: 10px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
`

export const StoreText = styled.div`
  font-size: 15px;
  color: grey;
  padding: 5px;
  width: 100%;
  height: 100%;
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: justify;
  border: 0px solid red;
`

export const ReadMoreText = styled.div`
  font-size: 13px;
  width: 100%;
  padding-right: 5px;
  text-align: right;
`

export const StoreButtonContainer = styled.div`
  width: 96px;
`

export const StoreButton = styled.button`
  background: #505050;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 96px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 40px;
`

const FormText = styled.div`
  flex-direction: column;
  margin-top: 0px;
`

const FormHeader = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #142630;
  width: 60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 40px;
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    font-size: 24px;
    width: 80%;
  }
`

const FormSubheader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  text-align: justify;
  font-size: 20px;
  color: #465f6d;
  line-height: 1.5;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 480px;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 360px;
    font-size: 14px;
  }
`

const FormContainer = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
  width: 640px;
  height: 640px;
`

export const StoreC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
  justify-content: justify;
`
export const BackLink = styled(Link)`
  margin-bottom: 80px;
`
